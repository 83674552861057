<script>
export default {
  props: {
    on_off: {
      type: Boolean,
      default: false,
    },
    index_of: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    backgroundStyles() {
      return {
        'blue-lighter': this.on_off,
        'gray-lighter': !this.on_off,
      }
    },
    indicatorStyles() {
      return { transform: this.on_off ? 'translateX(14px)' : 'translateX(0)' }
    },
  },
  methods: {
    toggle() {
      this.$emit('select_of', !this.on_off, this.index_of)
    },
  },
}
</script>
<template>
  <div>
    <span class="toggle-wrapper" role="checkbox" :aria-checked="on_off.toString()" tabindex="0" @click="toggle" @keydown.space.prevent="toggle">
      <span class="toggle-background" :class="backgroundStyles" />
      <span class="toggle-indicator" :style="indicatorStyles" />
    </span>
  </div>
</template>
<style lang="scss" scoped>
.blue-lighter {
  background-color: #0098D1;
}

.gray-lighter {
  background-color: #c2c2c2;
}

.toggle-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 18px;
  border-radius: 9999px;
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-background {
  display: inline-block;
  border-radius: 9999px;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.4s ease;
}

.toggle-indicator {
  position: absolute;
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  border-radius: 9999px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease;
}
</style>