<script>
import i18n from '@/i18n'
// import moment from 'moment'

import 'ol/ol.css'
import * as ol from 'ol'
import * as layer from 'ol/layer'
import * as source from 'ol/source'
import * as proj from 'ol/proj'
import * as geom from 'ol/geom'
import * as style from 'ol/style'
import LayerSwitcher from 'ol-layerswitcher'
// import Overlay from 'ol/Overlay'
// import CircleStyle from 'ol/style/Circle'

/**
 * Starter page component
 */
export default {
  page: {
    // title: 'แผนที่',
    meta: [{ name: 'description' }],
  },
  components: {},
  props: {},
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('plans_display')
    const src1 = require('@/assets/images/truck_img/4_wheels.png')
    const src2 = require('@/assets/images/truck_img/6_wheels.png')
    const src3 = require('@/assets/images/truck_img/10_wheels.png')
    const src4 = require('@/assets/images/truck_img/trailer2.png')
    const src5 = require('@/assets/images/truck_img/trailer.png')
    const src6 = require('@/assets/images/truck_img/tow_truck.png')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      lang: lang_obj,

      heightMap: '800px', // กำหนดความสูงด้วย methods getHeightDivMap()
      map: null,
      center: null,
      base_layer : null,
      zoom: 15,
      rotation: 0,

      src1: src1,
      src2: src2,
      src3: src3,
      src4: src4,
      src5: src5,
      src6: src6,
      coordinates: [11207429.40090933, 13541935.007697027],
      features: [
        {
          type: 'Feature',
          id: 'one',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [11207429.40090933, 13541935.007697027],
          },
        },
      ],
      pinPointLayer: '',
      obj_truck: {
        lat: '',
        lon: '',
        id_group_vehicle: '',
      },
    }
  },
  methods: {
    bt_map_truck(data) {

      this.obj_truck.lat = data.lat
      this.obj_truck.lon = data.lon
      this.obj_truck.id_group_vehicle = data.id_group_vehicle

      this.$bvModal.show('modal_map_truck')
      setTimeout(() => {
        this.arrBaseLayer()
        this.initiateMapTruck()
      }, 500)
    },
    arrBaseLayer(){
      // this.base_layer = [{"rr":"1"}]
      var arr = []
      var P_map = {}
      var dee_map_th2 = new layer.Tile({
        title: 'Dee-MapV2 (Thai)',
        type: 'base',
        visible: false,
        maxZoom: 22,
        source: new source.TileWMS({
          url: 'https://www.deemap.com/DeeMap/gwc/service/wms?', 
          params: {
            'customer_key': '9P8qe4ndbB8ep6hJ',
            'LAYERS': 'DeeMap2_THA_Map',
            'FORMAT': 'image/png8',
            'VERSION': '1.1.1',
            'TILED': true,
          },
        }),
      })
      arr.unshift(dee_map_th2)
      // console.log(arr)
      P_map.dee_map_th2 = dee_map_th2

      var dee_map_en2 = new layer.Tile({
        title: 'Dee-MapV2 (En)',
        type: 'base',
        visible: false,
        maxZoom: 22,
        source: new source.TileWMS({
          url: 'https://www.deemap.com/DeeMap/gwc/service/wms?',
          params: {
            customer_key: '9P8qe4ndbB8ep6hJ',
            'LAYERS': 'DeeMap2_THA_Map_EN',
            'FORMAT': 'image/png8',
            'VERSION': '1.1.1',
            'TILED': true,
          },
        }),
      })
      arr.unshift(dee_map_en2)
      P_map.dee_map_en2 = dee_map_en2
      
      var layerNokiaHere = new layer.Tile({
        title: 'HereMap',
        type: 'base',
        visible: false,
        maxZoom: 18,
        source: new source.XYZ({
          url: 'http://1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=o9W16gheao25dXp9a9Kg&app_code=Smo6mT91hk9YhqJSclXrSA&lg=THA&lg2=ENG&xnlp=CL_JSMv2.5.3,SID_A78335BD-4DDE-4BBB-B544-B856888E9E89',
        })
      })
      arr.unshift(layerNokiaHere)
      P_map.layerNokiaHere = layerNokiaHere

      var satellite = new layer.Tile({
        title: 'Satellite',
        type: 'base',
        visible: false,
        maxZoom: 20,
        source: new source.XYZ({
          url: 'http://1.aerial.maps.cit.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?app_id=o9W16gheao25dXp9a9Kg&app_code=Smo6mT91hk9YhqJSclXrSA&lg=THA&lg2=ENG',
        })
      })
      arr.unshift(satellite)
      P_map.satellite = satellite

      var openstreetmap = new layer.Tile({
        // A layer must have a title to appear in the layerswitcher
        title: 'OSM',
        // Again set this layer as a base layer
        type: 'base',
        visible: false,
        source: new source.OSM(),
      })
      arr.unshift(openstreetmap)
      P_map.openstreetmap = openstreetmap

      this.base_layer = {arr: arr, POINT: P_map}
    },
    initiateMapTruck() {
      var map = new ol.Map({
        target: this.$refs.map_truck,
        // layers: [
        //   new layer.Tile({
        //     source: new source.OSM(),
        //   }),
        // ],
        view: new ol.View({
          center: proj.fromLonLat([100.60379663561206, 13.676865941765966]),
          zoom: 10,
        }),
      })
      this.map = map

      var arr_layer = this.base_layer.arr
      this.base_layer.POINT['openstreetmap'].setVisible(true)
      var group_base = new layer.Group({
        // A layer must have a title to appear in the layerswitcher
        'title': 'Base Layer',
        fold: 'open',
        layers: arr_layer,
      })
      this.map.addLayer(group_base)

      var layerSwitcher = new LayerSwitcher({
        reverse: true,
        groupSelectStyle: 'group',
      })
      map.addControl(layerSwitcher)

      let lat = this.obj_truck.lat
      let lon = this.obj_truck.lon

      // console.log(lat)
      // console.log(lon)
      // console.log(this.obj_truck.id_group_vehicle)

      let coordinate = [parseFloat(lon), parseFloat(lat)]

      // remove layer if have layer already
      this.map.removeLayer(this.pinPointLayer)

      //set a new pinpoint at clicked coordinates
      var new_point = new ol.Feature({
        geometry: new geom.Point(proj.fromLonLat(coordinate)),
      })

      // check location index for select icon pinpoint
      var img_truck = this.src1
      if (this.obj_truck.id_group_vehicle == 1) {
        img_truck = this.src1
      } else if (this.obj_truck.id_group_vehicle == 2) {
        img_truck = this.src2
      } else if (this.obj_truck.id_group_vehicle == 3) {
        img_truck = this.src3
      } else if (this.obj_truck.id_group_vehicle == 4) {
        img_truck = this.src4
      } else if (this.obj_truck.id_group_vehicle == 5) {
        img_truck = this.src5
      } else if (this.obj_truck.id_group_vehicle == 6) {
        img_truck = this.src6
      }

      //set style of point
      new_point.setStyle(
        new style.Style({
          image: new style.Icon({
            // color: 'red',
            crossOrigin: 'anonymous',
            scale: 0.4,
            anchor: [0.5, 1],
            anchorOrigin: 'top-left',
            src: img_truck,
          }),
        }),
      )

      //put point on layer
      this.pinPointLayer = new layer.Vector({
        source: new source.Vector({
          features: [new_point],
        }),
      })

      // set name of layer
      this.pinPointLayer.set('name', 'point_0')
      // add point on map , set new center point and zoom value
      let new_center = proj.fromLonLat(coordinate)

      // view.animate({
      //   center: new_center,
      //   duration: 2000,
      // })

      this.map.addLayer(this.pinPointLayer)

      var zoom = this.map.getView().getZoom()
      if (zoom < 12) {
        zoom = 12
      }
      this.map.getView().animate({
        center: new_center,
        zoom: zoom,
        duration: 800,
      })
    },
  },
}
</script>

<template>
  <div class="text-center">    
    <b-modal id="modal_map_truck" centered size="lg" title="ตำแหน่งรถ" title-class="font-18" hide-footer>
      <b-row>
        <b-col lg="12" class="z-index map">
          <div id="map_truck" ref="map_truck" style="max-height: 67vh; min-height: 67vh" />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<style scoped>
@import '../../../node_modules/ol-layerswitcher/dist/ol-layerswitcher.css';
#map_truck {
  height: 100%;
}
.z-index.map {
  transition: 1s;
  z-index: 1;
  background-color: rgb(255, 255, 255);
}
.z-index.map.active {
  transition: 1s;
  z-index: 8;
  background-color: darkgrey;
}
.modal-scrollbar {
  overflow: hidden scroll;
}
.modal-scrollbar {
  overflow: auto;
}
</style>
