<script>
import i18n from '@/i18n'

import service from '@/server/http_service'
import Swal from 'sweetalert2'
import global_func from '@/global_function'
import VueNumericInput from 'vue-numeric-input'

/**
 * Starter page component
 */
export default {
  page: {
    // title: 'แผนที่',
    meta: [{ name: 'description' }],
  },
  components: { VueNumericInput },
  props: {
    sel_data_tt: {
      type: Array,
      default: () => [],
    },
    sel_data_ttg: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('personal_profile')
    const src1 = require('@/assets/images/truck_img/4_wheels.png')
    const src2 = require('@/assets/images/truck_img/6_wheels.png')
    const src3 = require('@/assets/images/truck_img/10_wheels.png')
    const src4 = require('@/assets/images/truck_img/trailer2.png')
    const src5 = require('@/assets/images/truck_img/trailer.png')
    const src6 = require('@/assets/images/truck_img/tow_truck.png')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      lang: lang_obj,

      src1: src1,
      src2: src2,
      src3: src3,
      src4: src4,
      src5: src5,
      src6: src6,

      fields: [
        { key: 'selected', label: '', class: 'text-center', width: '10%' },
        { key: 'truck_license', label: 'ทะเบียนรถ', class: 'text-center', width: '30%' },
        { key: 'id_group_vehicle', label: 'กลุ่มรถ', class: 'text-center', width: '30%' },
        { key: 'name_model', label: 'ประเภทรถ', class: 'text-center', width: '25%' },
        { key: 'load', label: 'น้ำหนักบรรทุก(ตัน)', class: 'text-center', width: '15%' },
      ],

      vehicleDataDTC: [],
      text_bb: '',
      vehicleDataDTCSave: [],
      default_group_vehicle: 1,
      default_name_group: 'รถบรรทุก 4 ล้อ',
      default_type_vehicle: 1,
      default_name_model: 'ปิคอัพ',
      default_load: 0,
      tmpTruckTypeSelect: [],

      have_selected: false,
      selected_all: false,
    }
  },
  methods: {
    bt_show_truck(text_bb) {
      this.text_bb = text_bb
      this.$bvModal.show('modal_list_truck')
      setTimeout(() => {
        this.initiateListTruck()
      }, 500)
    },
    async initiateListTruck() {
      this.selected_all = false
      service
        .postServiceDevweb('getVehicleDTC', {
          account_lang: this.account_lang,
          member_data: this.account_data,
          text_bb: this.text_bb,
          sel_data_tt: this.sel_data_tt,
          sel_data_ttg: this.sel_data_ttg,
          blackbox_id: '',
        })
        .then((rp) => {
          this.vehicleDataDTC = rp.data
          this.changeTTGroup()
        })
    },
    changeTTGroup() {
      var tg = this.default_group_vehicle
      for (var i = 0; i < this.sel_data_ttg.length; i++) {
        if (this.sel_data_ttg[i].id_group == tg) {
          this.default_name_group = this.sel_data_ttg[i].name_group_th
          break
        }
      }
      var valObj = this.sel_data_tt.filter((elem) => {
        if (elem.id_group == tg) {
          return elem
        }
      })
      // console.log(valObj)
      this.tmpTruckTypeSelect = valObj
      this.default_type_vehicle = valObj[0].id_model
      this.default_name_model = valObj[0].name_model
      this.changeTTtype()
    },
    changeTTtype() {
      var tt = this.default_type_vehicle
      for (var i = 0; i < this.sel_data_tt.length; i++) {
        if (this.sel_data_tt[i].id_model == tt) {
          this.default_name_model = this.sel_data_tt[i].name_model_th
          break
        }
      }
      this.afterChangeSomething()
    },
    changeLoad() {
      // console.log(this.default_load)
      this.afterChangeSomething()
    },
    changeChecked(val, index) {
      // console.log(this.vehicleDataDTC[index].selected)
      if (!this.vehicleDataDTC[index].selected) {
        this.vehicleDataDTC[index].id_group_vehicle = this.default_group_vehicle
        this.vehicleDataDTC[index].name_group = this.default_name_group
        this.vehicleDataDTC[index].id_type_vehicle = this.default_type_vehicle
        this.vehicleDataDTC[index].name_model = this.default_name_model
        this.vehicleDataDTC[index].load = this.default_load
        // console.log('selected:' + index)
      }
      this.$refs.table_vh.refresh()
    },
    changeCheckedAll(checked) {
      if (!checked) {
        for (var i = 0; i < this.vehicleDataDTC.length; i++) {
          this.vehicleDataDTC[i].selected = true
        }
      } else {
        for (var i2 = 0; i2 < this.vehicleDataDTC.length; i2++) {
          this.vehicleDataDTC[i2].selected = false
        }
      }
      this.afterChangeSomething()
    },
    afterChangeSomething() {
      this.vehicleDataDTCSave = []
      this.have_selected = false
      for (var i = 0; i < this.vehicleDataDTC.length; i++) {
        if (this.vehicleDataDTC[i].selected) {
          this.vehicleDataDTC[i].id_group_vehicle = this.default_group_vehicle
          this.vehicleDataDTC[i].name_group = this.default_name_group
          this.vehicleDataDTC[i].id_type_vehicle = this.default_type_vehicle
          this.vehicleDataDTC[i].name_model = this.default_name_model
          this.vehicleDataDTC[i].load = this.default_load
          this.vehicleDataDTC[i].id_member = this.account_data[0].id_member
          this.vehicleDataDTCSave.push(JSON.parse(JSON.stringify(this.vehicleDataDTC[i])))
          this.have_selected = true
        }
        if (this.vehicleDataDTC[i].truck_license == '') {
          this.vehicleDataDTC[i].truck_license = this.vehicleDataDTC[i].blackbox_id
          // this.vehicleDataDTC[i].truck_license = this.vehicleDataDTC[i].blackbox_id
        }
      }
    },
    async get_checkbox_before_save() {
      await this.afterChangeSomething()
      this.save_truck_dtc()
    },
    save_truck_dtc() {
      var self = this
      // console.log(this.vehicleDataDTCSave)

      if (!this.have_selected) {
        var str_err = 'กรุณาเลือกรถที่ต้องการบันทึก'
        var msg = ''
        msg += '<b-row>'
        msg += '<b-col>'
        msg += '<span>'
        msg += str_err
        msg += '</span>'
        msg += '</b-col>'
        msg += '</b-row>'
        global_func.alertMessage('', msg, 'warning')
        return
      }
      service.postService('saveVehicleDTC', this.vehicleDataDTCSave).then(async (rp) => {
        if (rp.message == 'save success') {
          //AFTER SAVE
          self.$bvModal.hide('modal_list_truck')
          global_func.alertMessage('บันทึกสำเร็จ', '', 'success')
          setTimeout(function () {
            self.$emit('afterSave')
          }, 500)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'บันทึกล้มเหลว',
            confirmButtonText: 'ปิด',
          })
        }
      })
    },
  },
}
</script>

<template>
  <div class="text-center">
    <b-modal id="modal_list_truck" centered size="lg" title="ข้อมูลรถ" title-class="font-18" hide-footer>
      <div class="row" style="max-height: 67vh; min-height: 67vh">
        <div class="table-responsive mb-0" style="max-height: 50vh; min-height: 42vh; border-bottom: 1px solid #eff2f7">
          <b-table ref="table_vh" :items="vehicleDataDTC" :fields="fields" head-variant="light" responsive="sm">
            <template v-slot:head(selected)>
              <div class="custom-control custom-checkbox">
                <input id="head_cb" v-model="selected_all" type="checkbox" class="custom-control-input" @click="changeCheckedAll(selected_all)" />
                <label class="custom-control-label" for="head_cb">&nbsp;</label>
              </div>
            </template>
            <template v-slot:cell(selected)="row">
              <div class="custom-control custom-checkbox">
                <input :id="row.index" v-model="row.item.selected" type="checkbox" class="custom-control-input" @click="changeChecked(row.item, row.index)" />
                <label class="custom-control-label" :for="row.index">&nbsp;</label>
              </div>
            </template>
            <template v-slot:cell(id_group_vehicle)="row">
              <b-row>
                <b-col class="text-right" lg="6">
                  <img v-if="row.value == '1'" src="@/assets/images/truck_img/4_wheels.png" style="height: 30px" />
                  <img v-else-if="row.value == '2'" src="@/assets/images/truck_img/6_wheels.png" style="height: 30px" />
                  <img v-else-if="row.value == '3'" src="@/assets/images/truck_img/10_wheels.png" style="height: 30px" />
                  <img v-else-if="row.value == '4'" src="@/assets/images/truck_img/trailer2.png" style="height: 30px" />
                  <img v-else-if="row.value == '5'" src="@/assets/images/truck_img/trailer.png" style="height: 30px" />
                  <img v-else-if="row.value == '6'" src="@/assets/images/truck_img/tow_truck.png" style="height: 30px" />
                </b-col>
                <b-col class="text-left" lg="6">
                  <span style="padding-left: 5px">{{ row.item.name_group }}</span>
                </b-col>
              </b-row>
            </template>
            <template v-slot:cell(location)="row">
              <div v-if="row.item.lat == null">
                <i class="ri-map-pin-line" />
              </div>
              <div v-else-if="row.item.lon == null">
                <i class="ri-map-pin-line" />
              </div>
              <div v-else>
                <b-link class="font-size-16" href="#" @click="show_map_truck(row.item)"><i class="ri-map-pin-line" /></b-link>
              </div>
            </template>
            <template v-slot:cell(on_off)="row">
              <SwitchButton :on_off="row.value" :index_of="row.item.no" @select_of="select_on_off" />
            </template>
            <template v-slot:cell(action)="row">
              <a v-b-tooltip.hover class="mr-3 text-primary" title="แก้ไข" href="javascript:void(0);" @click="editVehicle(row.item)">
                <i class="mdi mdi-pencil font-size-18" />
              </a>
              <a v-b-tooltip.hover class="text-danger" title="ลบ" href="javascript:void(0);" @click="deleteVehicle(row.item)">
                <i class="mdi mdi-trash-can font-size-18" />
              </a>
            </template>
          </b-table>
        </div>
        <b-row style="max-height: 7vh; min-height: 5vh; width: 100%">
          <form class="form-inline" style="padding: 5px; width: 100%">
            <b-col lg="12">
              <div class="d-flex justify-content-center input-group">
                <span style="font-weight: 600">
                  ตั้งค่าข้อมูลรถ
                </span>
              </div>
            </b-col>
            <b-col lg="4">
              <b-form-group label-cols-sm="2" label-cols-lg="4" label="กลุ่มรถ:" label-for="text">
                <div class="input-group">
                  <select v-model="default_group_vehicle" class="form-control" style="" @change="changeTTGroup()">
                    <option v-for="item in sel_data_ttg" :key="item.id_group" :value="item.id_group">{{ item.name_group_th }}</option>
                  </select>
                </div>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label-cols-sm="2" label-cols-lg="4" label="ประเภทรถ:" label-for="text">
                <div class="input-group">
                  <select v-model="default_type_vehicle" class="form-control" style="" @change="changeTTtype()">
                    <option v-for="item in tmpTruckTypeSelect" :key="item.id_model" :value="item.id_model">{{ item.name_model_th }}</option>
                  </select>
                </div>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label-cols-sm="2" label-cols-lg="4" label="น้ำหนักบรรทุก:" label-for="text">
                <div class="input-group text-center">
                  <VueNumericInput v-model="default_load" :min="0" :max="100" :step="0.5" align="center" @change="changeLoad()" />
                </div>
              </b-form-group>
            </b-col>
          </form>
        </b-row>
        <b-row style="max-height: 7vh; min-height: 5vh; width: 100%">
          <div class="text-center" style="margin-left: 45%">
            <b-button size="md" variant="primary" @click="get_checkbox_before_save()"> บันทึก </b-button>
          </div>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
.modal-scrollbar {
  overflow: hidden scroll;
}
.modal-scrollbar {
  overflow: auto;
}
</style>
