<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Layout from '@/views/layouts/main_profile'
import simplebar from 'simplebar-vue'
import service from '@/server/http_service'
import i18n from '@/i18n'
import Swal from 'sweetalert2'
import global_func from '@/global_function'
import TruckTypeComp from '@/components/select_components/select_truckType'
import SwitchButton from '@/components/switch_button'
import MapTruck from '@/components/modal/map_truck'
import ListTruck from '@/components/modal/list_truck_dtc'
import $ from 'jquery'

/**
 * Starter page component
 */
export default {
  page: {
    title: 'ข้อมูลรถ',
    meta: [{ name: 'description' }],
  },
  components: { Layout, simplebar, TruckTypeComp, SwitchButton, MapTruck, ListTruck },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('personal_profile')
    var lang_data = i18n.t('register_transporter')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      personal_data: {},
      check_lang: true,
      lang: lang_obj,
      lang2: lang_data,

      truck_data_status: false,
      status_truck: '0',
      dataTruckType: [],
      dataTruckTypeGroup: [],
      selectedTruckType: '',
      selectedTruckStatus: '0',
      search_truck_text: '',
      search_truck_type: '',

      //list truck
      vehicleData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: 'age',
      sortDesc: false,
      fields: [
        { key: 'no', label: 'ลำดับ', sortable: true, class: 'text-center', width: '10%' },
        { key: 'truck_license', label: 'ทะเบียนรถ', sortable: true, class: 'text-center', width: '20%' },
        { key: 'id_group_vehicle', label: 'กลุ่มรถ', sortable: true, class: 'text-center', width: '30%' },
        { key: 'name_model', label: 'ประเภทรถ', sortable: true, class: 'text-center', width: '10%' },
        { key: 'available', label: 'สถานะ', sortable: true, class: 'text-center', width: '10%' },
        { key: 'location', label: 'พิกัด', sortable: true, class: 'text-center', width: '10%' },
        { key: 'on_off', label: 'เปิด-ปิด', sortable: true, class: 'text-center', width: '10%' },
        { key: 'action', label: '' },
      ],
      small: false,
      //add dtc
      text_bb: '',
      oauth_provider: '',

      //add
      modal_form: {
        id_member_vehicle: '',
        truck_license: '',
        truck_type_group: '',
        truck_type: '',
        load: 0,
        blackbox_id: '',
        lat: '',
        lon: '',
        check_gps: false,
        member_data: [],
        account_lang: lang,
      },
      dataTruckTypeSelect: [],
      dataTruckTypeGroupSelect: [],
      tmpTruckTypeSelect: [],
      selTruckType: 0,
      selTruckTypeGroup: 0,
      mode_truck: '',
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.vehicleData.length
    },
  },
  async mounted() {
    await this.getTruckType()
    await this.getTruckTypeGroup()
    await this.getDataInfo()
    $(this.$refs.vuemodal).on('hidden.bs.modal', this.doSomethingOnHidden)
    this.oauth_provider = this.account_data[0].oauth_provider
  },
  methods: {
    // get data edit profile
    getDataInfo() {
      service
        .postService('getMemberVehicle', {
          s_model: this.search_truck_type,
          s_available: this.selectedTruckStatus,
          s_truck_license: this.search_truck_text,
          member_data: this.account_data,
          account_lang: this.account_lang,
        })
        .then((rp) => {
          this.vehicleData = rp.data
          this.text_bb = rp.text_bb
          // console.log(rp.data)
        })
    },
    activeSelectTruckType() {
      this.$bvModal.show('modal_select_truckType')
    },
    getTruckType_select: function (trucktype, trucktypegroup) {
      this.dataTruckType = trucktype
      this.dataTruckTypeGroup = trucktypegroup
      this.search_truck_type = ''
      this.selectedTruckType = ''

      var len_tt = this.dataTruckType.length
      var text_tt = ''
      var id_tt = ''
      for (var i = 0; i < len_tt; i++) {
        var tt = this.dataTruckType[i]
        if (tt.status == 'checked') {
          text_tt += tt.name_model_th + ','
          id_tt += tt.id_model + ','
        }
      }
      if (text_tt != '') {
        this.search_truck_type = id_tt.slice(0, -1)
        text_tt = text_tt.slice(0, -1)
        var arr_tt = text_tt.split(',')
        var len = arr_tt.length
        if (len > 2) {
          this.selectedTruckType = arr_tt[0] + ' ,' + arr_tt[1] + ', ' + '+' + parseInt(len - 2)
        } else {
          for (var j = 0; j < len; j++) {
            if (j > 0) {
              this.selectedTruckType += ', '
            }
            this.selectedTruckType += arr_tt[j]
          }
        }
      }
      // if (this.search_truck_type != '') {
      //   this.getTruckData()
      // }
      this.getTruckData()
    },
    doSomethingOnHidden() {
      // console.log('hide')
      this.getTruckData()
    },

    getTruckData() {
      var filter1 = this.search_truck_type
      var filter2 = this.selectedTruckStatus
      var filter3 = this.search_truck_text
      // console.log('filter 1: ' + filter1)
      // console.log('filter 2: ' + filter2)
      // console.log('filter 3: ' + filter3)
      this.getDataInfo()
    },
    timeout_search_truck() {
      var self = this
      clearTimeout(self.timeout_this_proc)
      this.timeout_this_proc = setTimeout(function () {
        self.getTruckData()
      }, 500)
    },
    addTruckDataDTC() {
      var self = this
      self.$refs.ref_truck_dtc.bt_show_truck(this.text_bb)
    },
    addTruckData() {
      this.mode_truck = 'add'
      this.tmpTruckTypeSelect = []
      this.selTruckType = 0
      this.selTruckTypeGroup = 0
      this.modal_form = {
        id_member_vehicle: '',
        truck_license: '',
        truck_type_group: '',
        truck_type: '',
        load: 0,
        blackbox_id: '',
        lat: '',
        lon: '',
        check_gps: false,
        member_data: this.account_data,
        account_lang: this.account_lang,
      }
      this.$bvModal.show('modal_add_truck')
    },
    getTruckType() {
      service.postService('getTruckType').then((rp) => {
        // console.log(rp)
        var data = JSON.parse(JSON.stringify(rp.data))
        // console.log(data)
        for (let index = 0; index < data.length; index++) {
          let row = data[index]
          let rowdata = {
            id_group: row.id_group,
            id_model: row.id_model,
            name_group_en: row.name_group_en,
            name_group_th: row.name_group_th,
            name_model_en: row.name_model_en,
            name_model_th: row.name_model_th,
            other_flag: row.other_flag,
            prices_pre_km: row.prices_pre_km,
            prices_start: row.prices_start,
            status: '',
          }
          this.dataTruckTypeSelect.push(JSON.parse(JSON.stringify(rowdata)))
        }
      })
    },
    getTruckTypeGroup() {
      service.postService('getTruckTypeGroup').then((rp) => {
        // console.log(rp)
        var data = rp.data
        for (let index = 0; index < data.length; index++) {
          var row = data[index]
          let rowdata = {
            id_group: row.id_group,
            name_group_en: row.name_group_en,
            name_group_th: row.name_group_th,
            status: '',
          }
          this.dataTruckTypeGroupSelect.push(JSON.parse(JSON.stringify(rowdata)))
        }
      })
    },
    changeTTGroup() {
      var tg = this.selTruckTypeGroup
      var valObj = this.dataTruckTypeSelect.filter((elem) => {
        if (elem.id_group == tg) {
          return elem
        }
      })
      this.tmpTruckTypeSelect = valObj
      this.selTruckType = 0
    },
    show_map_truck(data) {
      var self = this
      self.$refs.ref_truck.bt_map_truck(data)
    },
    select_on_off: function (on_off, index_of) {
      // console.log(on_off)
      index_of = index_of - 1
      this.vehicleData[index_of].on_off = on_off
      var msg = ''
      if (on_off) {
        this.vehicleData[index_of].inactive = '0'
        msg = 'เปิดใช้งานสำเร็จ'
      } else {
        this.vehicleData[index_of].inactive = '1'
        msg = 'ปิดใช้งานสำเร็จ'
      }
      this.setActionsVehicle(this.vehicleData[index_of], msg)
    },
    setActionsVehicle(row_t, msg) {
      // console.log(row_t)
      service.postService('onoffVehicle', row_t).then(async (rp) => {
        if (rp.message == 'edit success') {
          global_func.alertMessage(msg, '', 'success')
        } else {
          Swal.fire({
            icon: 'error',
            title: msg + 'ล้มเหลว',
            confirmButtonText: 'ปิด',
          })
        }
      })
    },
    editVehicle(row_t) {
      // console.log(row_t)
      this.mode_truck = 'edit'
      this.selTruckTypeGroup = row_t.id_group_vehicle
      this.changeTTGroup()
      this.selTruckType = row_t.id_type_vehicle

      this.modal_form = {
        id_member_vehicle: row_t.id_member_vehicle,
        truck_license: row_t.truck_license,
        truck_type_group: row_t.id_group_vehicle,
        truck_type: row_t.id_type_vehicle,
        load: row_t.load,
        blackbox_id: row_t.blackbox_id,
        lat: '',
        lon: '',
        check_gps: true,
        member_data: this.account_data,
        account_lang: this.account_lang,
      }
      this.$bvModal.show('modal_add_truck')
    },
    deleteVehicle(row_t) {
      var self = this
      Swal.fire({
        title: 'ยืนยันการลบ “ข้อมูลรถ”<br/>' + row_t.truck_license,
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then((result) => {
        if (result.value) {
          service.postService('delVehicle', row_t).then(async (rp) => {
            if (rp.message == 'delete success') {
              //AFTER SAVE
              self.$bvModal.hide('modal_add_truck')
              global_func.alertMessage('ลบข้อมูลสำเร็จ', '', 'success')
              setTimeout(function () {
                self.getDataInfo()
              }, 500)
            } else {
              Swal.fire({
                icon: 'error',
                title: 'ลบข้อมูลล้มเหลว',
                confirmButtonText: 'ปิด',
              })
            }
          })
        } else {
          return false
        }
      })
    },
    cancel_truck() {
      this.$bvModal.hide('modal_add_truck')
    },
    save_truck() {
      var self = this
      // console.log(this.mode_truck)
      this.modal_form.truck_type_group = this.selTruckTypeGroup
      this.modal_form.truck_type = this.selTruckType

      var str_err = ''
      if (this.modal_form.truck_license == '') {
        str_err += 'กรุณาระบุ ทะเบียนรถ<br/>'
      }
      if (this.modal_form.truck_type_group == '' || this.modal_form.truck_type_group == 0) {
        str_err += 'กรุณาเลือก กลุ่มรถ<br/>'
      }
      if (this.modal_form.truck_type == '' || this.modal_form.truck_type == 0) {
        str_err += 'กรุณาเลือก ประเภทรถ<br/>'
      }
      if (this.modal_form.load == '') {
        str_err += 'กรุณาระบุ น้ำหนักบรรทุก<br/>'
      }
      if (this.modal_form.load < 0) {
        this.modal_form.load = 0
      }
      if(this.modal_form.check_gps != true) {
        str_err += 'กรุณาระบุ เลขกล่อง ให้ถูกต้อง'
      }
      if (str_err != '') {
        var msg = ''
        msg += '<b-row>'
        msg += '<b-col>'
        msg += '<span>'
        msg += str_err
        msg += '</span>'
        msg += '</b-col>'
        msg += '</b-row>'
        global_func.alertMessage('', msg, 'warning')
        return
      }
      if (!this.checkFormValidity()) {
        // console.log('vallidity')
        return
      }
      if (this.mode_truck == 'edit') {
        service.postService('editVehicle', this.modal_form).then(async (rp) => {
          if (rp.message == 'edit success') {
            //AFTER SAVE
            self.$bvModal.hide('modal_add_truck')
            global_func.alertMessage('แก้ไขสำเร็จ', '', 'success')
            setTimeout(function () {
              self.getDataInfo()
            }, 500)
          } else {
            Swal.fire({
              icon: 'error',
              title: 'แก้ไขล้มเหลว',
              confirmButtonText: 'ปิด',
            })
          }
        })
      } else {
        service.postService('saveVehicle', this.modal_form).then(async (rp) => {
          if (rp.message == 'save success') {
            //AFTER SAVE
            self.$bvModal.hide('modal_add_truck')
            global_func.alertMessage('บันทึกสำเร็จ', '', 'success')
            setTimeout(function () {
              self.getDataInfo()
            }, 500)
          } else {
            Swal.fire({
              icon: 'error',
              title: 'บันทึกล้มเหลว',
              confirmButtonText: 'ปิด',
            })
          }
        })
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form_add.checkValidity()
      return valid
    },
    afterSaveTruckDTC() {
      this.getDataInfo()
    },
    check_bb_dtc(blackbox_id) {
      // console.log('blackbox_id: ' + blackbox_id)
      if (blackbox_id == '') {
        var msg = ''
        msg += '<b-row>'
        msg += '<b-col>'
        msg += '<span>'
        msg += 'ระบุเลขกล่องเพื่อค้นหา'
        msg += '</span>'
        msg += '</b-col>'
        msg += '</b-row>'
        global_func.alertMessage('', msg, 'warning')
        return
      }
      service
        .postServiceDevweb('getVehicleDTC', {
          account_lang: this.account_lang,
          member_data: this.account_data,
          text_bb: this.text_bb,
          sel_data_tt: this.dataTruckTypeSelect,
          sel_data_ttg: this.dataTruckTypeGroupSelect,
          blackbox_id: blackbox_id,
          mode_truck: this.mode_truck,
        })
        .then((rp) => {
          if (rp.status == 'ok') {
            this.modal_form.check_gps = true
            global_func.alertMessage('พบข้อมูล สามารถใช้งานได้', '', 'success')
            let vh_dtc = rp.data[0]
            this.modal_form.truck_license = vh_dtc.truck_license
            this.modal_form.lat = vh_dtc.r_lat
            this.modal_form.lon = vh_dtc.r_lon
          } else {
            this.modal_form.check_gps = false
            this.modal_form.truck_license = ''
            Swal.fire({
              icon: 'error',
              title: 'ไม่พบข้อมูล หรือ มีข้อมูลอยู่ในระบบแล้ว',
              confirmButtonText: 'ปิด',
            })
          }
        })
    },
  },
}
</script>

<template>
  <Layout>
    <b-row>
      <b-col>
        <!-- MAIN TRUCK PAGE-->
        <div style="padding: 2rem 2rem 0rem 2rem">
          <b-row>
            <b-col lg="2" class="col-margin-bot-1">
              <div class="form-group" @click="activeSelectTruckType()">
                <b-form-input v-model="selectedTruckType" class="form-control" type="text" placeholder="เลือกประเภทรถ" disabled />
              </div>
              <b-modal id="modal_select_truckType" ref="vuemodal" centered size="lg" :title="lang.truck_info.truck_type" title-class="font-size-24" hide-footer>
                <TruckTypeComp :obj="[dataTruckType, dataTruckTypeGroup, 'truck']" @sendTruckTypeData="getTruckType_select" />
              </b-modal>
            </b-col>
            <b-col lg="2" class="col-margin-bot-1">
              <select v-model="selectedTruckStatus" class="form-control" @change="getTruckData()">
                <option value="0" selected hidden>สถานะ</option>
                <option value="1">ทั้งหมด</option>
                <option value="2">ว่าง</option>
                <option value="3">ไม่ว่าง</option>
              </select>
            </b-col>
            <b-col lg="3" class="col-margin-bot-1">
              <div class="input-group prepend">
                <b-form-input id="search_truck_text" v-model="search_truck_text" type="text" class="form-control custom-input-prepend" placeholder="ค้นหา ทะเบียนรถ" @keyup="timeout_search_truck()" />
                <div class="input-group-prepend custom-input-group-prepend" @click="getTruckData()">
                  <span class="input-group-text custom-input-group-prepend-text search"><i class="ri-search-line" /></span>
                </div>
              </div>
            </b-col>
            <b-col lg="1" />
            <b-col v-if="oauth_provider == 'dtc'" lg="2" class="col-margin-bot-1">
              <div class="d-flex justify-content-center big_btn_green" @click="addTruckDataDTC()">
                <span class="margin-top-bot-auto"><i class="ri-add-line" /> เพิ่มข้อมูลรถจาก DTC</span>
              </div>
            </b-col>
            <b-col v-else lg="2" />
            <b-col lg="2" class="col-margin-bot-1">
              <div class="d-flex justify-content-center big_btn_green" @click="addTruckData()">
                <span class="margin-top-bot-auto"><i class="ri-add-line" /> เพิ่มข้อมูลรถ</span>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="!truck_data_status">
            <simplebar class="card-body truck-display" style="padding: 0px">
              <div class="table-responsive mb-0" style="border-bottom: 1px solid #eff2f7">
                <!-- max-height: 65vh; min-height: 65vh;  -->
                <b-table :items="vehicleData" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" head-variant="light">
                  <template v-slot:cell(id_group_vehicle)="row">
                    <b-row>
                      <b-col class="text-right" lg="6">
                        <img v-if="row.value == '1'" src="@/assets/images/truck_img/4_wheels.png" style="height: 30px" />
                        <img v-else-if="row.value == '2'" src="@/assets/images/truck_img/6_wheels.png" style="height: 30px" />
                        <img v-else-if="row.value == '3'" src="@/assets/images/truck_img/10_wheels.png" style="height: 30px" />
                        <img v-else-if="row.value == '4'" src="@/assets/images/truck_img/trailer2.png" style="height: 30px" />
                        <img v-else-if="row.value == '5'" src="@/assets/images/truck_img/trailer.png" style="height: 30px" />
                        <img v-else-if="row.value == '6'" src="@/assets/images/truck_img/tow_truck.png" style="height: 30px" />
                      </b-col>
                      <b-col class="text-left" lg="6">
                        <span style="padding-left: 5px">{{ row.item.name_group }}</span>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-slot:cell(available)="row">
                    <div v-if="row.value == '0'">
                      <div class="badge font-size-14 badge-soft-success">ว่าง</div>
                    </div>
                    <div v-else-if="row.value == '1'">
                      <div class="badge font-size-14 badge-soft-danger">ไม่ว่าง</div>
                    </div>
                    <!-- <div class="badge font-size-14" :class="{ 'badge-soft-danger': `${row.value}` === '1', 'badge-soft-success': `${row.value}` === '0' }">{{ row.value }}</div> -->
                  </template>
                  <template v-slot:cell(location)="row">
                    <div v-if="row.item.lat == null">
                      <i class="ri-map-pin-line" />
                    </div>
                    <div v-else-if="row.item.lon == null">
                      <i class="ri-map-pin-line" />
                    </div>
                    <div v-else>
                      <b-link class="font-size-16" href="#" @click="show_map_truck(row.item)"><i class="ri-map-pin-line" /></b-link>
                    </div>
                  </template>
                  <template v-slot:cell(on_off)="row">
                    <SwitchButton :on_off="row.value" :index_of="row.item.no" @select_of="select_on_off" />
                  </template>
                  <template v-slot:cell(action)="row">
                    <a v-b-tooltip.hover class="mr-3 text-primary" title="แก้ไข" href="javascript:void(0);" @click="editVehicle(row.item)">
                      <i class="mdi mdi-pencil font-size-18" />
                    </a>
                    <a v-b-tooltip.hover class="text-danger" title="ลบ" href="javascript:void(0);" @click="deleteVehicle(row.item)">
                      <i class="mdi mdi-trash-can font-size-18" />
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" />
                    </ul>
                  </div>
                </div>
              </div>
            </simplebar>
          </b-row>
          <b-row v-else>
            <b-col class="text-center">
              <br />
              <br />
              <br />
              <h2>ไม่มีข้อมูล</h2>
            </b-col>
          </b-row>
        </div>
        <!-- END MAIN TRUCK PAGE -->
      </b-col>
    </b-row>

    <b-modal id="modal_add_truck" centered size="lg" :title="mode_truck == 'edit' ? 'แก้ไขข้อมูลรถ' : 'เพิ่มข้อมูลรถ'" title-class="font-18">
      <simplebar style="max-height: 45vh; min-height: 40vh; padding-top: 0px">
        <b-row>
          <b-col lg="12">
            <form ref="form_add" style="padding: 5px">
              <b-form-group label-cols-sm="1" label-cols-lg="2" label="ทะเบียนรถ:" label-for="text">
                <div class="input-group">
                  <b-form-input v-model="modal_form.truck_license" type="text" class="form-control" placeholder="ระบุทะเบียนรถ" />
                </div>
              </b-form-group>
              <b-form-group label-cols-sm="1" label-cols-lg="2" label="กลุ่มรถ:" label-for="text">
                <div class="input-group">
                  <select v-model="selTruckTypeGroup" class="form-control" @change="changeTTGroup()">
                    <option value="0" selected hidden>เลือกกลุ่มรถ</option>
                    <option v-for="item in dataTruckTypeGroupSelect" :key="item.id_group" :value="item.id_group">{{ item.name_group_th }}</option>
                  </select>
                </div>
              </b-form-group>
              <b-form-group label-cols-sm="1" label-cols-lg="2" label="ประเภทรถ:" label-for="text">
                <div class="input-group">
                  <select v-model="selTruckType" class="form-control">
                    <option value="0" selected hidden>เลือกประเภทรถ</option>
                    <option v-for="item in tmpTruckTypeSelect" :key="item.id_model" :value="item.id_model">{{ item.name_model_th }}</option>
                  </select>
                </div>
              </b-form-group>
              <b-form-group label-cols-sm="1" label-cols-lg="2" label="น้ำหนักบรรทุก:" label-for="text">
                <div class="input-group prepend">
                  <b-form-input v-model="modal_form.load" type="number" min="0" max="100" step="0.1" class="form-control add_load prepend" placeholder="ระบุจำนวนรถ" />
                  <div class="input-group-prepend">
                    <span class="input-group-text custom-input-group-prepend-text" style="cursor: default">ตัน</span>
                  </div>
                  <!-- <b-form-input v-model="modal_form.load" type="range" min="0" max="100" step="0.5" class="form-control add_load prepend" placeholder="ระบุจำนวนรถ" />
                  <div class="input-group-prepend">
                    <span class="input-group-text custom-input-group-prepend-text" style="cursor: default">{{ modal_form.load }} ตัน</span>
                  </div> -->
                </div>
              </b-form-group>
              <b-form-group v-if="oauth_provider == 'dtc'" label-cols-sm="1" label-cols-lg="2" label="เลขกล่อง:" label-for="text">
                <div class="form-inline">
                  <div class="input-group mb-3">
                    <input v-model="modal_form.blackbox_id" type="text" class="form-control blackbox" placeholder="ระบุเลขกล่อง DTC" :disabled="mode_truck == 'edit'" />
                    <div v-if="mode_truck == 'add'" class="input-group-append">
                      <button class="btn btn-light" type="button" @click="check_bb_dtc(modal_form.blackbox_id)">ตรวจสอบ</button>
                    </div>
                    <div v-else class="input-group-append">
                      <button class="btn btn-light" type="button" disabled>ตรวจสอบ</button>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </form>
          </b-col>
        </b-row>
      </simplebar>
      <template #modal-footer class="d-flex align-items-center">
        <b-button size="md" variant="success" @click="save_truck(mode_truck)"> ยืนยัน </b-button>
        <b-button size="md" variant="outline-secondary" @click="cancel_truck()"> ยกเลิก </b-button>
      </template>
    </b-modal>
    <MapTruck ref="ref_truck" />
    <ListTruck ref="ref_truck_dtc" :sel_data_tt="dataTruckTypeSelect" :sel_data_ttg="dataTruckTypeGroupSelect" @afterSave="afterSaveTruckDTC" />
  </Layout>
</template>
<style scoped>
@media only screen and (min-width: 500px) {
  body {
    overflow: scroll;
  }
}
@media only screen and (min-width: 1200px) {
  body {
    overflow: hidden;
  }
}
.h-100vh {
  height: 100% !important;
}
hr {
  border: 1px solid #e4e4e4;
}
.truck-display {
  max-height: 77.5vh;
  min-height: 77.5vh;
}
.add_load {
  border-right: 0px;
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}
.form-control.blackbox:disabled {
  background-color: #f7f7f7;
}
</style>
